var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "summary-container" },
    [
      _c("SummaryPage", {
        attrs: {
          summaryTitle: _vm.summaryTitle,
          summaryList: _vm.summaryList,
          lessonNum: 11,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }