<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="18"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/background-map.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/china-star-yellow.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/china-star-red.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/usa-star-yellow.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/usa-star-red.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/canada-star-yellow.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/canada-star-red.svg"),
          id: 3,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/china.svg"),
          id: 1,
          centencePinyin: "Nǐ hǎo!",
          sentenceHanzi: "你好！",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/canada.svg"),
          id: 2,
          centencePinyin: "",
          sentenceHanzi: "Hello!",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-19/usa.svg"),
          id: 3,
          centencePinyin: "",
          sentenceHanzi: "Hello! Bonjour!",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











