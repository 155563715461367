<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-83.mp3"),
      },
      timeStampList: [6.2, 9.3, 12.6, 15.7, 18.9, 22.1, 25.3, 28.7],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.",
            chineseWords: "我的猫在哪？",
          },
          {
            id: 2,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo Ài.",
            chineseWords: "我的猫在哪？",
          },
          {
            id: 3,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì BB",
            chineseWords: "不在桌子下边，",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "不在椅子下边，",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "也不在床下边。",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "看！",
          },
          {
            id: 7,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "那本书下是什么？"
          },
          {
            id: 8,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "那本书下是我的猫！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
