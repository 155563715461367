<template>
  <div class="summary-container">
    <SummaryPage
      :summaryTitle="summaryTitle"
      :summaryList="summaryList"
      :lessonNum="11"
    />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  data() {
    return {
      summaryTitle: {
        pinyin: "Nǐ shì nǎ guó rén ？",
        hanzi: "你是哪国人？",
      },
      summaryList: [
        {
          pinyin: "shén me",
          hanzi: "什么",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-19-zhongguoren-bubble.svg"),
          bgColor: 1,
        },
        {
          pinyin: "nǎ",
          hanzi: "哪",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-19-na-bubble.svg"),
          bgColor: 2,
        },
        {
          pinyin: "guó",
          hanzi: "国",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-19-guo-bubble.svg"),
          bgColor: 3,
        },
        {
          pinyin: "zhōng guó rén",
          hanzi: "中国人",
          imgUrl: require("@/assets/img/12-Vocabulary/lesson-19-zhongguoren-bubble.svg"),
          bgColor: 1,
        },
      ],
    };
  },
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>