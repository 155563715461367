<template>
  <div class="game-container">
    <BingoGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      @changeStatus="changeStatus"
      :lessonType="1"
      :bingoIndexList="[3, 5, 7]"
      :totalStars="totalStars"
    />
  </div>
</template>

<script>
import BingoGame from "@/components/Course/GamePage/BingoGame";
export default {
  data() {
    return {
      totalStars: 3,
      questionInfoList: [
        {
          id: 3,
          pinyin: "wǔ ge mèimei",
          hanzi: "哪个是爸爸？",
        },

        {
          id: 5,
          pinyin: "sān ge gēge",
          hanzi: "哪个是妈妈？",
        },

        {
          id: 7,
          pinyin: "jiějie hé dìdi",
          hanzi: "哪个是老师？",
        },
      ],
      nameString: ["leftList", "MiddleList", "rightList"],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-1.svg"),
          },
          {
            id: 4,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-4.svg"),
          },
          {
            id: 7,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-7.svg"),
          },
        ],
        MiddleList: [
          {
            id: 2,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-2.svg"),
          },
          {
            id: 5,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-5.svg"),
          },
          {
            id: 8,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-8.svg"),
          },
        ],
        rightList: [
          {
            id: 3,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-3.svg"),
          },
          {
            id: 6,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-6.svg"),
          },
          {
            id: 9,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-bingo-images/lesson-19/image-9.svg"),
          },
        ],
      },
    };
  },
  components: {
    BingoGame,
  },
  methods: {
    changeStatus(item) {
      // console.log(item);
      for (let i = 0; i <= 2; i++) {
        this.asideElementInfo[this.nameString[i]].forEach((ele) => {
          if (ele.id === item.id) {
            ele.done = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
